<template>
  <div>
    <div class="layout">
      <div class="title">
        <van-icon
          name="arrow-left"
          size="20"
          color="#9B9B9B"
          class="icon-left"
          @click="closePage"
        />
        <span>区域限制</span>
      </div>
      <div>
        <van-cell
          is-link
          title="区域限制"
          :value="accredit.accredit_name"
          @click="areaSelect"
        />
      </div>
    </div>
    <van-popup v-model="isShow">
      <div class="select-title">
        <span>请选择区域</span>
        <van-icon name="cross" class="location" @click="close" />
      </div>
      <div class="checkbox-button">
        <!--<van-button type="primary" size="mini" @click="checkAll">-->
        <!--全选-->
        <!--</van-button>-->
        <!--<van-button type="info" size="mini" @click="toggleAll">反选</van-button>-->
        <van-radio-group
          v-model="result"
          ref="checkboxGroup"
          class="checkbox-group"
        >
          <van-radio
            v-for="(item, index) in district"
            :key="index"
            :disabled="item.is_select"
            :name="item.id"
            shape="square"
            class="checkbox"
          >
            {{ item.name }}
          </van-radio>
        </van-radio-group>
        <div>
          <van-button type="info" size="small" @click="confirm">
            确定
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getShopAccredit, setShopAccredit } from "@/api/shop";
import { Toast } from "vant";

export default {
  name: "county-supervise",
  data() {
    return {
      isShow: false,
      setShopId:
        this.$route.query.setShopId === undefined
          ? 0
          : this.$route.query.setShopId,
      result: "",
      accredit: {
        accredit_ids: [],
        accredit_name: "",
        id: 0,
        shop_name: "",
      },
      district: [],
    };
  },
  watch: {
    result: function () {
      let new_accredit_name = [];
      this.district.forEach((item, index) => {
        if (this.result * 1 === item.id * 1) {
          new_accredit_name[0] = item.name;
        }
      });
      new_accredit_name = new_accredit_name.join(",");
      this.accredit.accredit_name = new_accredit_name;
    },
  },
  async mounted() {
    const res = await getShopAccredit({ set_shop_id: this.setShopId });
    if (res.code * 1 === 1) {
      this.result = res.data.set_shop_info.accredit_ids;
      this.accredit = res.data.set_shop_info;
      this.district = res.data.district;
    }
  },
  methods: {
    confirm() {
      this.setShopAccredit();
    },
    async setShopAccredit() {
      const res = await setShopAccredit({
        set_shop_id: this.setShopId,
        accredit_ids: this.result,
      });
      if (res.code * 1 === 1) {
        Toast.success(res.msg);
        this.isShow = false;
      } else {
        Toast.fail(res.msg);
      }
    },
    areaSelect() {
      this.isShow = true;
    },
    checkAll() {
      // this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      // this.$refs.checkboxGroup.toggleAll();
    },
    close() {
      //关闭弹层的操作
      this.isShow = false;
    },
    closePage() {
      // 关闭本页面的操作
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup--center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);

  .van-checkbox {
    padding: 10px 10px 0 0;
  }
}

.checkbox {
  min-width: 160px;
  flex-shrink: 0;
  margin-left: 10px;
  margin-bottom: 10px;
}

.checkbox-button {
  padding: 12px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.select-title {
  position: relative;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid rgb(238, 238, 238);

  .location {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.layout {
  background-color: #fff;
  height: 100vh;
}

.title {
  position: relative;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
  span {
    font-size: 21px;
  }
  .icon-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}

.van-cell {
  display: flex;
}

.van-cell__title {
  flex: none;
  font-size: 17px;
}

.van-cell__value {
  text-align: left;
  margin-left: 50px;
}

// 营业额样式
.row-layout {
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  font-weight: 600;
  box-sizing: border-box;

  .input {
    margin-left: 10px;
    width: 78px;
    height: 28px;
  }
}
</style>
